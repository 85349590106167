/* Start by setting display:none to make this hidden.
   Then we position it in relation to the viewport window
   with position:fixed. Width, height, top and left speak
   for themselves. Background we set to 80% white with
   our animation centered, and no-repeating */
.loading-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.loading-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.spinner-border-container {
  padding: 15px 18px;
  border: 1px solid grey;
  border-radius: 4px;
  background: white;
}

.spinner-border-container .spinner-border {
  margin-bottom: -5px;
}

.spinner-border {
  z-index: 100;
}

.dataTables_wrapper div.dataTables_processing {
  display: block;
  z-index: 1000 !important;
  padding-top: 15px;
  padding-bottom: 35px;
  background: none;
}

/* When the body has the loading class, we turn
   the scrollbar off with overflow:hidden */
body.loading {
  overflow: hidden;
}

/* Anytime the body has the loading class, our
   modal element will be visible */
body.loading .loading-modal {
  display: block;
}
